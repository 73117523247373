import CategoryService from '../services/category.service'

const initialState = { status: { loading: false }, categories: null }

export const category = {
  namespaced: true,
  state: initialState,
  actions: {
    async getCategories ({ commit }) {
      await CategoryService.getCategories().then(
        categories => {
          // console.log(categories)
          commit('SET_CATEGORIES_LIST', categories)
          return Promise.resolve(categories)
        },
        error => {
          commit('SET_CATEGORIES_LIST_FAILURE')
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    SET_CATEGORIES_LIST (state, { data: { data } }) {
      // console.log(data)
      state.status.loading = true
      state.categories = [...data]
    },
    SET_CATEGORIES_LIST_FAILURE (state) {
      state.status.loading = false
      state.categories = null
    }
  }
}

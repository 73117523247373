import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { swiperVideo } from "./swiperVideo.module";
import { videoByCategory } from "./videoByCategory.module";
import { videosByUser } from "./videosByUser.module";
import { category } from "./category.module";
import { comments } from "./comments.module";
import { user } from "./user.module";
import { uploadFile } from "./uploadFile.module";
import { game } from "./game.module";
import { hashtagTop } from "./hashtagTop.module";
import { hashtagTrendingFavorite } from "./hashtagTrendingFavorite.module";
import { usersFollowing } from "./usersFollowing.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    swiperVideo,
    videoByCategory,
    videosByUser,
    category,
    comments,
    user,
    uploadFile,
    game,
    hashtagTop,
    hashtagTrendingFavorite,
    usersFollowing
  }
});

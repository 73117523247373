import UserService from "../services/user.service";

const initialState = {
    status: { loading: false },
    followings: []
};

export const usersFollowing = {
    namespaced: true,
    state: initialState,
    actions: {
        async getUserFollowings({ commit }) {
            try {
                commit("SET_USER_FOLLOWINGS_NULL");
                await UserService.getUserFollowings().then(
                    hashtags => {
                        if (hashtags.data) {
                            commit("SET_USER_FOLLOWINGS_SUCCESS", hashtags);
                            return Promise.resolve(hashtags);
                        }
                    },
                    error => {
                        commit("SET_USER_FOLLOWINGS_FAILURE");
                        return Promise.reject(error);
                    }
                );
            } catch (error) {
                console.log(error);
            }
        }
    },
    mutations: {
        SET_USER_FOLLOWINGS_NULL(state) {
            state.status.loading = false;
            state.followings = null;
        },
        SET_USER_FOLLOWINGS_SUCCESS(state, { data: { data } }) {
            state.status.loading = true;
            state.followings = data;
        },
        SET_USER_FOLLOWINGS_FAVORITE_FAILURE(state) {
            state.status.loading = false;
            state.followings = null;
        }
    }
};

import axios from "axios";
import authHeader from "./auth-header";
// import Constants from './Constants'
// const API_URL = 'http://edufami.localhost/api/'

class AuthService {
  login(user) {
    return axios
      .post("login", {
        phone: user.phone,
        password: user.password
      })
      .then(({ data: { data } }) => {
        if (data.access_token) {
          localStorage.setItem("user", JSON.stringify(data));
        }
        return data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    return axios.post("logout", { headers: authHeader() }).then(response => {
      return response.data.data;
    });
  }

  register(user) {
    // console.log("register localStorage");
    localStorage.removeItem("user");
    return axios
      .post("register", {
        name: user.name,
        phone: user.phone,
        email: user.email,
        password: user.password,
        password_confirmation: user.password
      })
      .then(({ data: { data } }) => {
        if (data.access_token) {
          localStorage.setItem("user", JSON.stringify(data));
        }
        return data;
      });
  }

  update(user) {
    return axios
      .post("user/update", {
        name: user.name,
        birthdate: user.birthdate,
        phone: user.phone,
        email: user.email,
        password: user.password,
        password_confirmation: user.password_confirmation
      })
      .then(({ data: { data } }) => {
        if (data.access_token) {
          localStorage.setItem("user", JSON.stringify(data));
        }
        return data;
      });
  }
}

export default new AuthService();

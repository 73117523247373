import HashtagsService from "../services/hashtags.service";

const initialState = {
    status: { loading: false },
    hashtags: []
};

export const hashtagTrendingFavorite = {
    namespaced: true,
    state: initialState,
    actions: {
        async getHashtagTrendingFavorite({ commit }) {
            try {
                commit("SET_HASHTAG_TRENDING_FAVORITE_NULL");
                await HashtagsService.getHashtagTranding().then(
                    hashtags => {
                        if (hashtags.data) {
                            commit(
                                "SET_HASHTAG_TRENDING_FAVORITE_SUCCESS",
                                hashtags
                            );
                            return Promise.resolve(hashtags);
                        }
                    },
                    error => {
                        commit("SET_HASHTAG_TRENDING_FAVORITE_FAILURE");
                        return Promise.reject(error);
                    }
                );
            } catch (error) {
                console.log(error);
            }
        }
    },
    mutations: {
        SET_HASHTAG_TRENDING_FAVORITE_NULL(state) {
            state.status.loading = false;
            state.hashtags = null;
        },
        SET_HASHTAG_TRENDING_FAVORITE_SUCCESS(state, { data: { data } }) {
            state.status.loading = true;
            state.hashtags = data;
        },
        SET_HASHTAG_TRENDING_FAVORITE_FAILURE(state) {
            state.status.loading = false;
            state.hashtags = null;
        }
    }
};

import CommentService from "../services/comment.service";

const initialState = { status: { loading: false }, comments: null };

export const comments = {
  namespaced: true,
  state: initialState,
  actions: {
    async getComments({ commit }, { videoId }) {
      commit("CLEAR_COMMENT");
      await CommentService.getComments(videoId).then(
        comments => {
          if (comments.data) {
            commit("setCommentsListSuccess", comments);
            return Promise.resolve(comments);
          }
        },
        error => {
          commit("setCommentsListFailure");
          return Promise.reject(error);
        }
      );
    },
    async postComment({ commit }, { comment, videoId }) {
      await CommentService.postComment(comment, videoId).then(
        comment => {
          commit("ADD_COMMENT", comment);
          return Promise.resolve(comment);
          // console.log(response.data.data)
          // this.contentComments = response.data.data
        },
        error => {
          this.contentVideo =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }
  },
  mutations: {
    setCommentsListSuccess(state, { data: { data } }) {
      state.status.loading = true;
      state.comments = data;
    },
    setCommentsListFailure(state) {
      state.status.loading = false;
      state.comments = null;
    },
    ADD_COMMENT(state, { data }) {
      state.status.loading = true;
      state.comments = [...state.comments, data];
    },
    CLEAR_COMMENT(state) {
      state.status.loading = false;
      state.comments = null;
    }
  }
};

import HashtagsService from "../services/hashtags.service";

const initialState = {
  status: { loading: false },
  hashtags: []
};

export const hashtagTop = {
  namespaced: true,
  state: initialState,
  actions: {
    async getTopHashtags({ commit }) {
      try {
        commit("SET_TOP_HASHTAGS_LIST_NULL");
        await HashtagsService.getTopHashtags().then(
          hashtags => {
            if (hashtags.data) {
              commit("SET_TOP_HASHTAGS_LIST_SUCCESS", hashtags);
              return Promise.resolve(hashtags);
            }
          },
          error => {
            commit("SET_TOP_HASHTAGS_LIST_FAILURE");
            return Promise.reject(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    async getHashtagTrendingFavorite({ commit }) {
      try {
        commit("SET_HASHTAG_TRENDING_FAVORITE_NULL");
        await HashtagsService.getTopHashtags().then(
          hashtags => {
            if (hashtags.data) {
              commit("SET_TOP_HASHTAGS_LIST_SUCCESS", hashtags);
              return Promise.resolve(hashtags);
            }
          },
          error => {
            commit("SET_TOP_HASHTAGS_LIST_FAILURE");
            return Promise.reject(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_TOP_HASHTAGS_LIST_NULL(state) {
      state.status.loading = false;
      state.hashtags = null;
    },
    SET_TOP_HASHTAGS_LIST_SUCCESS(state, { data }) {
      state.status.loading = true;
      state.hashtags = data.data;
    },
    SET_TOP_HASHTAGS_LIST_FAILURE(state) {
      state.status.loading = false;
      state.hashtags = null;
    },
  }
};

import AuthService from "../services/auth.service";
import { db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : {
      status: { loggedIn: false },
      user: {},
      messageLoginError: null,
      messageRegisterError: null,
      messageUpdateError: null
    };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, user) {
      await AuthService.login(user).then(
        user => {
          // console.log(user.user.uuid);

          commit("loginSuccess", user);
          //setDoc(doc(db, "users", user.user.uuid), {});
          return Promise.resolve(user);
        },
        error => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    async register({ commit }, user) {
      // console.log(user)
      localStorage.removeItem("user");
      await AuthService.register(user).then(
        response => {
          commit("registerSuccess", response);
          return Promise.resolve(response);
        },
        error => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    async update({ commit }, user) {
      await AuthService.update(user).then(
        response => {
          commit("updateSuccess", response);
          return Promise.resolve(response);
        },
        error => {
          commit("updateFailure");
          return Promise.reject(error);
        }
      );
    },
    LogOut({ commit }) {
      // const user = null
      localStorage.removeItem("user");
      commit("logout");
      AuthService.logout().then(
        () => {
          commit("logout");
        },
        error => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    async LogoutURI({ commit }) {
      await AuthService.logout().then(
        () => {
          commit("logout");
        },
        error => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    SetNullSession({ commit }) {
      localStorage.removeItem("user");
      commit("logout");
    }
  },
  mutations: {
    loginSuccess(state, user) {
      // console.log(user)
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = {};
      state.messageLoginError =
        "El número no se encuentra registrado, registrate y comienza a disfrutar y compartir.";
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = {};
    },
    registerSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
      state.messageRegisterError =
        "El correo o teléfono ya existe, intenta nuevamente.";
    },
    updateSuccess(state, user) {
      state.user = user;
    },
    updateFailure(state) {
      state.messageRegisterError = "Los datos estan incorrectos";
    }
  }
};

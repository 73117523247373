import axios from 'axios'
import authHeader from './auth-header'

class UploadFilesService {
  getVideosByUser () {
    return axios.get('videosByUser', { headers: { ...authHeader(), 'content-type': 'multipart/form-data' } })
  }

  uploadFileVideo (uploadObject , onUploadProgress) {
    const formData = new FormData();
    var file = uploadObject.file;
    formData.append('file', file, file.name);
    var cloned_tags = JSON.parse(JSON.stringify(uploadObject.tags));
    var formatedTags = this.formatTags(cloned_tags);

    formData.append('hashtags', formatedTags);
    return axios.post('upload', formData, {
      headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' },
      onUploadProgress
    })
  }

  formatTags(tags){
    var i;
    for(i = 0; i < tags.length; i++){
        tags[i].hashtag = tags[i]['text'];
        delete tags[i].text;
    }

    var _json_tags = JSON.stringify(tags, ['hashtag'] );
    return _json_tags;
  }
}

export default new UploadFilesService()

import UserService from "../services/user.service";

const initialState = {
    status: { loading: false },
    user: {},
    userProfile: {},
    videos: []
};

export const user = {
    namespaced: true,
    state: initialState,
    actions: {
        async getUserProfile({ commit }, userId) {
            try {
                commit("SET_USER_NULL");
                await UserService.getUserProfile(userId).then(
                    response => {
                        if (response.data) {
                            commit("SET_USER_SUCCESS", response);
                            return Promise.resolve(response);
                        }
                    },
                    error => {
                        commit("SET_USER_NULL");
                        return Promise.reject(error);
                    }
                );
            } catch (error) {
                commit("SET_USER_NULL");
                // console.log(error);
            }
        },
        async getVideosByUser({ commit }, userId = null) {
            try {
                commit("SET_VIDEOS_LIST_BY_USER_NULL");
                await UserService.getVideosByUser(userId).then(
                    videos => {
                        if (videos && videos.data) {
                            commit("SET_VIDEOS_LIST_BY_USER_SUCCESS", videos);
                            return Promise.resolve(videos);
                        }
                        // commit("SET_VIDEOS_LIST_BY_USER_NULL");
                    },
                    error => {
                        commit("SET_VIDEOS_LIST_BY_USER_NULL");
                        return Promise.reject(error);
                    }
                );
            } catch (error) {
                commit("SET_VIDEOS_LIST_BY_USER_NULL");
                console.log(error);
            }
        },
        async postUser({ commit }, user) {
            await UserService.postUser(user).then(
                user => {
                    // commit('ADD_USER_LOGIN', user)
                    return Promise.resolve(user);
                    // console.log(response.data.data)
                    // this.contentUsers = response.data.data
                },
                error => {
                    console.log(error);
                    // this.contentVideo =
                    //   (error.response && error.response.data) ||
                    //   error.message ||
                    //   error.toString()
                }
            );
        },
        async postFollowUser({ commit }, { userId, actionFollower }) {
            commit("SET_FOLLOW_USER", actionFollower);
            await UserService.postFollowUser(userId, actionFollower).then(
                user => {
                    return Promise.resolve(user);
                    // console.log(response.data.data)
                    // this.contentUsers = response.data.data
                },
                error => {
                    console.log(error);
                    // this.contentVideo =
                    //   (error.response && error.response.data) ||
                    //   error.message ||
                    //   error.toString()
                }
            );
        }
    },
    mutations: {
        // setUsersListSuccess (state, { data: { data } }) {
        //   state.status.loading = true
        //   state.user = data
        // },
        // setUsersListFailure (state) {
        //   state.status.loading = false
        //   state.user = null
        // },
        SET_VIDEOS_LIST_BY_USER_NULL(state) {
            state.status.loading = false;
            state.videos = [];
        },
        SET_VIDEOS_LIST_BY_USER_SUCCESS(state, { data: { data } }) {
            state.status.loading = true;
            state.videos = data || [];
        },
        SET_USER_NULL(state) {
            state.status.loading = false;
            state.userProfile = {};
        },
        SET_USER_SUCCESS(state, { data: { data } }) {
            state.status.loading = true;
            state.userProfile = data;
        },
        SET_FOLLOW_USER(state, actionFollower) {
            state.userProfile.follow_user = actionFollower;
        }
    }
};

import axios from "axios";
import authHeader from "./auth-header";

class UserService {
  getVideosByUser(userId) {
    if (userId !== undefined && userId !== null) {
      return axios.get("videosByUser?user_id=" + userId, {
        headers: authHeader()
      });
    } else {
      return axios.get("videosByUser", { headers: authHeader() });
    }
  }

  getVideosFavoritesByUser(userId) {
    return axios.get("videosFavoritesByUser?user_id=" + userId, {
      headers: authHeader()
    });
  }
  getUserFollowings() {
    return axios.get("user/followings", {
      headers: authHeader()
    });
  }

  getUserProfile(userId) {
    return axios.get("show-user?user_id=" + userId, { headers: authHeader() });
  }

  postFollowUser(userId, actionFollower) {
    return axios
      .post("follow-user", {
        user_id: userId,
        action_follower: actionFollower
      })
      .then(response => {
        return response.data;
      });
  }
}

export default new UserService();

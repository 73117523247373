import axios from "axios";
// import { API_URL } from './Constants'
import authHeader from "./auth-header";

// const API_URL = 'http://edufami.localhost/api/'

class GameService {
  getGames() {
    return axios.get("games", { headers: authHeader() });
  }

  getGameShow(id) {
    return axios.get(`games/show?game_id=${id}`, { headers: authHeader() });
  }
}

export default new GameService();

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import VueAwesomeSwiper from "vue-awesome-swiper";

// import style (<= Swiper 5.x)
import "swiper/dist/css/swiper.css";

import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@fortawesome/fontawesome-free/css/all.css";

// axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_SERVICE;
// axios.defaults.baseURL = "https://adminfunkvi.3dm.com.co/api/";

// axios.interceptors.response.use(undefined, function(error) {
//     if (error) {
//         const originalRequest = error.config;
//         if (error.response.status == 401) {
//             // //   store.dispatch("auth/LogOut");
//             return router.push("/login");
//         }
//         if (!error.response) {
//             console.log("Please check your internet connection.");
//             return router.push("/error");
//         }
//     }
// });

axios.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        if (error.response && error.response.status === 401) {
            store.dispatch("auth/SetNullSession");
            return router.push("/login");
        }
        if (!error.response) {
            console.log("Please check your internet connection.");
            return router.push("/error");
        }
        return Promise.reject(error);
    }
);

// Add a request interceptor
// axios.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   return config
// }, function (error) {
//   console.log(error)
//   // Do something with request error
//   return Promise.reject(error)
// })

Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

// let app
// auth.onAuthStateChanged(() => {
//   if (!app) {
//     new Vue({
//       router,
//       store,
//       vuetify,
//       render: h => h(App)
//     }).$mount('#app')
//   }
// })

// new Vue({
//   router,
//   store,
//   vuetify,
//   render: h => h(App)
// }).$mount('#app')

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");

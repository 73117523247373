import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// const Home = () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
// const Login = () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
// const Register = () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
// const ProfileUser = () => import(/* webpackChunkName: "about" */ '../views/ProfileUser.vue')
// const VideosByCategory = () => import(/* webpackChunkName: "about" */ '../views/VideosByCategory.vue')
// const Bookmark = () => import(/* webpackChunkName: "about" */ '../views/Bookmark.vue')

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "about" */ "../views/Home.vue")
  },
  {
    path: "/top-hashtags",
    name: "top-hashtag",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TopHashtags.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue")
  },
  {
    path: "/register",
    name: "Register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Register.vue")
  },
  {
    path: "/edit-user",
    name: "EditUser",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EditUser.vue")
  },
  {
    path: "/profile",
    name: "ProfileUser",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProfileUser.vue")
  },
  {
    path: "/video-by-category",
    name: "VideosByCategory",
    // props: true,
    props: route => ({ id_category: route.query.id_category }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VideosByCategory.vue")
  },
  {
    path: "/video-by-hashtag",
    name: "VideosByHashtag",
    // props: true,
    props: route => ({ hashtag: route.query.hashtag }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VideosByHashtag.vue")
  },
  {
    path: "/bookmark",
    name: "Bookmark",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Bookmark.vue")
  },
  {
    path: "/favorite",
    name: "Favorite",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Favorite.vue")
  },
  {
    path: "/upload-video",
    name: "UploadVideo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UploadVideo.vue")
  },
  {
    path: "/profile-user-visited",
    name: "ProfileUserVisited",
    props: route => ({ userVisitedId: route.query.userVisitedId }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProfileUserVisited.vue")
  },
  {
    path: "/list-games",
    name: "Games",
    // props: route => ({ userVisitedId: route.query.userVisitedId }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Games.vue")
  },
  {
    path: "/game-show",
    name: "GameFullScreen",
    // props: true,
    props: route => ({ game: route.query.nameGame }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GameFullScreen.vue")
  },
  // {
  //   path: "/games/game-show",
  //   name: "GameFullScreen",
  //   // props: true,
  //   props: route => ({ game: route.query.nameGame }),
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/GameFullScreen.vue")
  // },
  {
    path: "/error",
    name: "ErrorPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ErrorPage.vue")
  },
  {
    path: "/chats",
    name: "ChatRooms",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChatRooms.vue")
  },
  {
    path: "/chats/message",
    name: "ChatMessage",
    props: route => ({ id: route.query.id }),
    // props: route => ({ uuid: route.query.uuid }),
    // props: dynamicPropsFn,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChatMessage.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;

import axios from "axios";
// import { API_URL } from './Constants'
import authHeader from "./auth-header";

// const API_URL = 'http://edufami.localhost/api/'

class HashtagsService {
  getTopHashtags() {
    return axios.get("top-hashtag", { headers: authHeader() });
  }

  getHashtagTranding() {
    return axios.get("top-hashtag-follower", { headers: authHeader() });
  }
}

export default new HashtagsService();

import { initializeApp } from "firebase/app";
import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAybaIbqsFbJrNkr_vUMIwHT8wNozz89cw",
  authDomain: "vue-app-6e257.firebaseapp.com",
  databaseURL: "https://vue-app-6e257-default-rtdb.firebaseio.com",
  projectId: "vue-app-6e257",
  storageBucket: "vue-app-6e257.appspot.com",
  messagingSenderId: "761104821350",
  appId: "1:761104821350:web:fc945a92fec13ab1d5d1a7",
  measurementId: "G-TTCS4KJFPQ"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

enableIndexedDbPersistence(db).catch(err => {
  if (err.code == "failed-precondition") {
    console.log(err.code);
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code == "unimplemented") {
    console.log(err.code);

    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
});

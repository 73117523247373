<template>
  <v-app>
    <v-main>
      <div v-if="!isMobile">
        <v-app-bar color="menu-principal deep-purple accent-4" dark>
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" absolute bottom temporary>
          <v-list nav dense>
            <v-list-item-group
              v-model="group"
              active-class="deep-purple--text text--accent-4"
            >
              <router-link to="/">
                <v-list-item>
                  <v-list-item-title>
                    <span>Inicio</span>
                  </v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/register">
                <v-list-item>
                  <v-list-item-title>
                    <span>Registrar usuario</span>
                  </v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/registrar">
                <v-list-item>
                  <v-list-item-title>
                    <span>Editar perfil</span>
                  </v-list-item-title>
                </v-list-item>
              </router-link>

              <v-list-item @click="logout">
                <v-list-item-title>Salir</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<script>
import { isMobile } from "mobile-device-detect";

export default {
  name: "App",
  data: () => ({
    drawer: false,
    group: null
  }),
  computed: {
    isMobile: function() {
      return isMobile;
    }
  },
  watch: {
    group() {
      this.drawer = false;
    }
  },
  methods: {
    // async logout () {
    //   await this.$store.dispatch('auth/LogOut')
    //   this.$router.push('/login')
    // }
    logout: function() {
      this.$store.dispatch("auth/LogoutURI").then(() => {
        this.$router.push("/login");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-principal {
  margin-bottom: 0.7%;
}
</style>

import GameService from "../services/game.service";

const initialState = { status: { loading: false }, games: [], game: {} };

export const game = {
  namespaced: true,
  state: initialState,
  actions: {
    async getGames({ commit }) {
      await GameService.getGames().then(
        games => {
          commit("setGamesListSuccess", games);
          // console.log("setGamesListSuccess", games);
          return Promise.resolve(games);
        },
        error => {
          commit("setGamesListFailure");
          return Promise.reject(error);
        }
      );
    },
    async getGameShow({ commit }, gameId) {
      try {
        await GameService.getGameShow(gameId).then(
          game => {
            commit("setGameShowSuccess", game);
            return Promise.resolve(game);
          },
          error => {
            commit("setGameShowFailure");
            return Promise.reject(error);
          }
        );
      } catch (error) {
        // commit("setGameShowFailure");
        console.log(error);
      }
    }
  },
  mutations: {
    setGamesListNull(state) {
      state.status.loading = true;
      state.games = [];
    },
    setGamesListSuccess(state, { data: { data } }) {
      state.status.loading = true;
      state.games = [...data];
    },
    setGamesListFailure(state) {
      state.status.loading = false;
      state.games = [];
    },
    setGameShowNull(state) {
      state.status.loading = true;
      state.game = {};
    },
    setGameShowSuccess(state, { data: { data } }) {
      console.log("setGameShowSuccess", data);
      state.status.loading = true;
      state.game = data;
    }
  }
};

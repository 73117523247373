import CommentService from "../services/comment.service";
import CategoryService from "../services/category.service";
import UserService from "../services/user.service";

const initialState = {
    status: { loading: false },
    videos: [],
    nexPage: 0
};

export const swiperVideo = {
    namespaced: true,
    state: initialState,
    actions: {
        async getVideosByCategory({ commit }, idCategory) {
            try {
                commit("SET_VIDEOS_LIST_NULL");
                await CategoryService.getVideosByCategory(idCategory).then(
                    videos => {
                        if (videos.data) {
                            commit("SET_VIDEOS_LIST_SUCCESS", videos);
                            return Promise.resolve(videos);
                        }
                    },
                    error => {
                        commit("SET_VIDEOS_LIST_FAILURE");
                        return Promise.reject(error);
                    }
                );
            } catch (error) {
                console.log(error);
            }
        },
        getVideos({ commit }) {
            try {
                commit("SET_VIDEOS_LIST_NULL");
                CommentService.getVideos().then(
                    videos => {
                        if (videos.data.data) {
                            commit("SET_VIDEOS_LIST_SUCCESS", videos.data);
                            return Promise.resolve(videos);
                        }
                    },
                    error => {
                        commit("SET_VIDEOS_LIST_FAILURE");
                        return Promise.reject(error);
                    }
                );
            } catch (error) {
                console.log(error);
            }
        },
        getVideosHashtags({ commit }, hashtag) {
            try {
                commit("SET_VIDEOS_LIST_NULL");
                CommentService.getVideosHashtags(hashtag).then(
                    videos => {
                        if (videos.data) {
                            commit("SET_VIDEOS_LIST_SUCCESS", videos);
                            return Promise.resolve(videos);
                        }
                    },
                    error => {
                        commit("SET_VIDEOS_LIST_FAILURE");
                        return Promise.reject(error);
                    }
                );
            } catch (error) {
                console.log(error);
            }
        },
        async getMoreVideos({ commit }, currentPage) {
            try {
                await CommentService.getVideos(currentPage).then(
                    videos => {
                        commit("SET_MORE_VIDEOS_LIST_SUCCESS", videos.data);
                        return Promise.resolve(videos);
                    },
                    error => {
                        commit("SET_VIDEOS_LIST_FAILURE");
                        return Promise.reject(error);
                    }
                );
            } catch (error) {
                // console.log(error);
            }
        },
        async getVideosBookmark({ commit }) {
            try {
                commit("SET_VIDEOS_LIST_NULL");
                await CommentService.getVideosBookmark().then(
                    videos => {
                        if (videos.data) {
                            commit("SET_VIDEOS_LIST_SUCCESS", videos);
                            return Promise.resolve(videos);
                        }
                    },
                    error => {
                        commit("SET_VIDEOS_LIST_FAILURE");
                        return Promise.reject(error);
                    }
                );
            } catch (error) {
                console.log(error);
            }
        },
        async postLikeVideo({ commit }, { videoId, likeVideo, keyIndex }) {
            // console.log('llega like: ' + likeVideo)
            try {
                commit("SET_LIKE_VIDEO", { videoId, likeVideo, keyIndex });
                await CommentService.postLikeVideo(videoId, likeVideo).then(
                    video => {
                        return Promise.resolve(video);
                    },
                    error => {
                        console.log(error);
                        // commit('SET_VIDEOS_LIST_FAILURE')
                        // return Promise.reject(error)
                    }
                );
            } catch (error) {
                console.log(error);
            }
        },
        async postLikeVideoSave({ commit }, { videoId, saveVideo, keyIndex }) {
            try {
                commit("SET_USER_SAVE_VIDEO", { videoId, saveVideo, keyIndex });
                await CommentService.postLikeVideoSave(videoId, saveVideo).then(
                    video => {
                        return Promise.resolve(video);
                    },
                    error => {
                        console.log(error);
                        // commit('SET_VIDEOS_LIST_FAILURE')
                        // return Promise.reject(error)
                    }
                );
            } catch (error) {
                console.log(error);
            }
        },
        async postFollowUser({ commit }, { userId, followerUser, keyIndex }) {
            try {
                commit("SET_USER_FOLLOW_USER_VIDEO", { followerUser, userId });
                await UserService.postFollowUser(userId, followerUser).then(
                    user => {
                        return Promise.resolve(user);
                    },
                    error => {
                        console.log(error);
                    }
                );
            } catch (error) {
                console.log(error);
            }
        },
        async videoComments({ commit }, videoId) {
            try {
                await CommentService.getComments(videoId).then(
                    comments => {
                        commit("SET_COMMENTS_VIDEO", comments);
                        return Promise.resolve(comments);
                    },
                    error => {
                        console.log(error);
                        // this.contentVideo =
                        //   (error.response && error.response.data) ||
                        //   error.message ||
                        //   error.toString()
                    }
                );
            } catch (error) {
                console.log(error);
            }
        }
    },
    mutations: {
        SET_VIDEOS_LIST_NULL(state) {
            state.status.loading = false;
            state.videos = null;
        },
        SET_VIDEOS_LIST_SUCCESS(state, { data }) {
            state.status.loading = true;
            state.videos = data;
            //   state.nexPage = data.next_page;
        },
        SET_VIDEOS_LIST_FAILURE(state) {
            state.status.loading = false;
            state.videos = null;
        },
        SET_LIKE_VIDEO(state, { videoId, likeVideo, keyIndex }) {
            const videos = state.videos.find(
                (video, index) => index === keyIndex
            );
            videos.like_video = likeVideo;
        },
        SET_USER_SAVE_VIDEO(state, { videoId, saveVideo, keyIndex }) {
            const videos = state.videos.find(
                (video, index) => index === keyIndex
            );
            videos.save_video = saveVideo;
        },

        SET_USER_FOLLOW_USER_VIDEO(state, { followerUser, userId }) {
            state.videos.map(video => {
                if (video.user.id === userId) {
                    video.follower_user = followerUser;
                }
            });
        },
        SET_MORE_VIDEOS_LIST_SUCCESS(state, { data }) {
            state.status.loading = true;
            state.videos = [...state.videos, ...data];
            // state.nexPage = data.next_page;
        }
    }
};

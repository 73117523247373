import axios from "axios";
// import { API_URL } from './Constants'
import authHeader from "./auth-header";

// const API_URL = 'http://edufami.localhost/api/'

class CommentService {
    postComment(comment, videoId) {
        return axios
            .post(
                "video/comment",
                {
                    comment: comment.comment,
                    id_video: videoId
                },
                { headers: authHeader() }
            )
            .then(response => {
                // if (response.data.access_token) {
                //   localStorage.setItem('user', JSON.stringify(response.data))
                // }

                // console.log(response)

                return response.data;
            });
    }

    postLikeVideo(videoId, likeVideo) {
        // console.log(likeVideo)
        return axios
            .post(
                "video/like",
                {
                    id_video: videoId,
                    like_video: likeVideo
                },
                { headers: authHeader() }
            )
            .then(response => {
                // if (response.data.access_token) {
                //   localStorage.setItem('user', JSON.stringify(response.data))
                // }

                // console.log(response)
                return response.data.data;
            });
    }

    postLikeVideoSave(videoId, saveVideo) {
        // console.log(likeVideo)
        return axios
            .post(
                "video/user-save",
                {
                    id_video: videoId,
                    save_video: saveVideo
                },
                { headers: authHeader() }
            )
            .then(response => {
                // if (response.data.access_token) {
                //   localStorage.setItem('user', JSON.stringify(response.data))
                // }

                // console.log(response)
                return response.data.data;
            });
    }

    getComments(videoId) {
        return axios.get("video/comments?id_video=" + videoId, {
            headers: authHeader()
        });
    }

    getVideos(currentPage) {
        var url =
            currentPage != undefined ? "videos?page=" + currentPage : "videos";
        return axios.get(url, { headers: authHeader() });
    }

    getVideosHashtags(hashtag) {
        return axios.get("videos?hashtag=" + hashtag, {
            headers: authHeader()
        });
    }

    getVideosBookmark() {
        return axios.get("userSaveVideos", { headers: authHeader() });
    }
}

export default new CommentService();

import axios from "axios";

export default function authHeader() {
    const user = JSON.parse(localStorage.getItem("user"));
    var header = {};

    if (user && user.access_token) {
        header = { Authorization: "Bearer " + user.access_token };
        axios.defaults.headers.common.Authorization = `Bearer ${user.access_token}`;
    }
    return header;
    // var cors = {
    //     "Access-Control-Allow-Origin": "*",
    //     "Content-Type": "multipart/form-data; charset=utf-8;",
    //     "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS"
    //     // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    // };

    // header = { ...header, ...cors };

    // console.log({ ...header, ...cors })
}

import CategoryService from "../services/category.service";

const initialState = { status: { loading: false }, videos: null };

/*
 * POR EL MOMENTO NO SE ESTÁ USANDO
 *
 * */

export const videoByCategory = {
  namespaced: true,
  state: initialState,
  actions: {
    async getVideosByCategory({ commit }, idCategory) {
      await CategoryService.getVideosByCategory(idCategory).then(
        videos => {
          if (videos.data) {
            commit("setVideosListSuccess", videos);
            return Promise.resolve(videos);
          }
        },
        error => {
          commit("setVideosListFailure");
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    setVideosListNull(state) {
      state.status.loading = true;
      state.videos = null;
    },
    setVideosListSuccess(state, { data: { data } }) {
      state.status.loading = true;
      state.videos = [...data];
    },
    setVideosListFailure(state) {
      state.status.loading = false;
      state.videos = null;
    }
  }
};

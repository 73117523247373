import axios from "axios";
// import { API_URL } from './Constants'
import authHeader from "./auth-header";

// const API_URL = 'http://edufami.localhost/api/'

class CategoryService {
  getVideosByCategory(idCategory) {
    return axios.get("videosByCategory?id_category=" + idCategory, {
      headers: authHeader()
    });
  }

  getCategories() {
    return axios.get("categories", { headers: authHeader() });
  }
}

export default new CategoryService();

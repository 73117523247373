import UploadFilesService from '../services/uploadFiles.service'

const initialState = { status: { progress: 0, message: '' } }

export const uploadFile = {
  namespaced: true,
  state: initialState,
  actions: {
    async postVideoUpload ({ commit }, file) {
      await UploadFilesService.uploadFileVideo(file, (event) => {
        commit('PROGRESS_FILE', Math.round((100 * event.loaded) / event.total))
      })
        .then(
          file => {
            // commit('ADD_FILE', file)
            return Promise.resolve(file)
          // console.log(response.data.data)
          // this.contentComments = response.data.data
          },
          error => {
            console.log(error)
          // this.contentVideo =
          //   (error.response && error.response.data) ||
          //   error.message ||
          //   error.toString()
          }
        ).catch(error => {
          console.log(error)
        })
    }
  },
  mutations: {
    ADD_FILE (state, { data }) {
      state.status.progress = true
    },
    PROGRESS_FILE (state, progress) {
      state.status.progress = progress
    },
    CLEAR_VIDEO (state) {
      state.status.progress = 0
    },
    ERROR_VIDEO (state) {
      state.status.progress = 0
    }
  }
}

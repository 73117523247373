import UserService from "../services/user.service";

const initialState = { status: { loading: false }, videos: null };

/*
 * POR EL MOMENTO NO SE ESTÁ USANDO
 *
 * */

export const videosByUser = {
  namespaced: true,
  state: initialState,
  actions: {
    // async getVideosByUser({ commit }, idUser = null) {
    //   await CategoryService.getVideosByUser(idUser).then(
    //     videos => {
    //       if (videos.data) {
    //         commit("setVideosListSuccess", videos);
    //         return Promise.resolve(videos);
    //       }
    //     },
    //     error => {
    //       commit("setVideosListFailure");
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    async getVideosFavorites({ commit }, idUser = null) {
      await UserService.getVideosFavoritesByUser(idUser).then(
        videos => {
          if (videos.data) {
            commit("setVideosListSuccess", videos.data);
            return Promise.resolve(videos);
          }
        },
        error => {
          commit("setVideosListFailure");
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    setVideosListNull(state) {
      state.status.loading = true;
      state.videos = null;
    },
    setVideosListSuccess(state, { data }) {
      state.status.loading = true;
      state.videos = data;
    },
    setVideosListFailure(state) {
      state.status.loading = false;
      state.videos = null;
    }
  }
};
